import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
//import store from '../store'
//import { getToken } from '@/utils/auth'

//Create Axios instance
const service = axios.create({
  baseURL:  process.env.VUE_APP_BASE_API, // base of API_ URL,
  Timeout: 5000, // request timeout
  
})


let rk = (len) =>{ 
    let text = ""
    let chars = "abcdefghijklmnopqrstuvwxyz1234567890" 
    for( let i=0; i < len; i++ ) {
            text += chars.charAt(Math.floor(Math.random() * chars.length))
    } 
    return text; 
};

const getToken = () => localStorage.getItem('ecmtctoken');
const get_current_usid =() => localStorage.getItem('ec_current_usid');
const get_override_cifid  =() => localStorage.getItem('override_cifid');
  
//Request interceptor 
service.interceptors.request.use(
  config => {
    if(config.url.split("?").length > 1) {
      config.url = config.url + "&rk=" + rk(20); //add random key to prevent caching
    } else {
      config.url = config.url + "?rk=" + rk(20); //add random key to prevent caching
    } 
    if (getToken() != null && getToken() !== '') {
      config.headers ['ECAPP-TWXHEADER'] = getToken() // let each request carry a custom token. Please modify it according to the actual situation
    }
    if(get_current_usid() != null && get_current_usid() !== "") {
      config.headers ['ec_current_usid'] = get_current_usid() 
    }
    if(get_override_cifid() != null && get_override_cifid() !== "") {
      config.headers ['ec_override_cifid'] = get_override_cifid() 
    }
    return config
  },
  error => {
    // Do something with request error
    console.log("here");
    console.log(error) // for debug
    Promise.reject(error)
  }
)


//Response interceptor
service.interceptors.response.use(
  response => {
    /**
     *If the code is not 20000, it is a mistake and can be modified according to your own business
     */
    const res = response;
    if (res.status === 502) { 
      console.log("remove session");
      localStorage.removeItem('ecmtctoken'); 
      location.replace('/');
      return Promise.reject('error')
    } else {
      return response;
    }
  },
  error => {  
    if (error.response.status === 502) { 
      console.log("remove session");
      localStorage.removeItem('ecmtctoken'); 
      location.replace('/');
    }

  /*  Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    }) */
    
    return Promise.reject(error)
  }
)
  
 
 
 

export default service;