import request from '@/utils/request'

//Login
export function loadPaginatedBiboActivationCodes(page) {
  return request({ url: 'vbiboactivationcodes', method: 'post', data: {'page': page} })
} 

export function confirm_biboregister(data) {
  return request({ url: 'confirm_register', method: 'post', data: data})
} 


export function save_biboregister(data) {
  return request({ url: 'save_register', method: 'post', data: data})
} 


//Login
export function loadBIBO_summary(params) {
  return request({ url: 'vbibo', method: 'post', data: params })
} 


export function getBiboEndorsersForRepositioning(page) {
  return request({ url: 'vbiboendorsersrepositionlist', method: 'post', data: {'page': page} })
} 

export function processBiboEndorserRepositioning(params) {
  return request({ url: 'vproceedbiboendorsersreposition', method: 'post', data: params})
} 
