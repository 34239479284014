import { isAuthGuardActive } from '../constants/config'
import { setCurrentUser, getCurrentUser } from '.'
import {isBiboONLY_user}  from "@/api/bibo";
export default (to, from, next) => {   
 // console.log(window.location.host);

      if(window.location.host === "my.orbixvictus.com"   ) {
          document.title = "Orbix Victus International";
          document.querySelector("link[rel*='icon']").href = "favicon.ico";
          localStorage.setItem("pt", "ovi"); //plan type
      }
      if(window.location.host === "ng.aimglobalinc.com") {
        document.title = "Alliance in Motion Global Inc."; 
        document.querySelector("link[rel*='icon']").href = "ecicon.ico";
        localStorage.setItem("pt", "edp"); //plan type
      }
      if(window.location.host === "dtc.aimglobalinc.com") {
        document.title = "Alliance in Motion Global Inc."; 
        document.querySelector("link[rel*='icon']").href = "ecicon.ico";
        localStorage.setItem("pt", "edp"); //plan type
      }
      if(window.location.host === "ec5.empoweredconsumerism.com" || window.location.host === "localhost:8081" ) {
        document.title = "Empowered Consumerism";  
        localStorage.setItem("pt", "ecp"); //plan type
        document.querySelector("link[rel*='icon']").href = "ecicon.ico";
      }
      if(window.location.host === "my.economydriver.com" ) {
        document.title = "Economy Driver";  
        document.querySelector("link[rel*='icon']").href = "ecicon.ico";
        localStorage.setItem("pt", "edp"); //plan type
      }
      if(window.location.host === "my.economydesigner3.com" ) {
        document.title = "Economy Designer 3.0";  
        document.querySelector("link[rel*='icon']").href = "ecicon.ico";
        localStorage.setItem("pt", "edp"); //plan type
      }

      if(window.location.host === "my.bibosuperapp.com"    ) {
        document.title = "BIBO";  
        document.querySelector("link[rel*='icon']").href = "BIBO_ICON.ico";
        localStorage.setItem("pt", "bibo"); //plan type
      }

      if(window.location.host === "my.iglow247.com" ) {
        document.title = "iGlow 247";  
        document.querySelector("link[rel*='icon']").href = "iglow_icon.ico";
        localStorage.setItem("pt", "iglow"); //plan type  
      }

      if(window.location.host === "my.ecrowdcommerce.com"  ) {
        document.title = "E-Crowd Commerce";  
        document.querySelector("link[rel*='icon']").href = "ECC_ICON.ico";
        localStorage.setItem("pt", "ecc"); //plan type
      }


      if(to.path.indexOf("/sign-in") >= 0) {  
        console.log("hello");
        
        if(localStorage.getItem('ecmtctoken') != null && localStorage.getItem('ecmtctoken') !== '') {
          if(localStorage.getItem("pt") === 'ecp' || localStorage.getItem("pt") === 'edp' || localStorage.getItem("pt") === 'iglow'){
            next('/dashboardecp/reminderspage') ;
          } else if(localStorage.getItem("pt") === 'ovi'){
            next('/dashboard/reminderspage') ;
          } else if(localStorage.getItem("pt") === 'ecc'){
            next('/dashboardecc') ;
          } else {
            next('/dashboardbibo') ;
          }
        }

        if(localStorage.getItem('pt') === "ecp" && to.path.indexOf("/sign-in-ec") < 0) {
          next('/sign-in-ec')
        }

        if(localStorage.getItem('pt') === "edp" && to.path.indexOf("/sign-in-ed") < 0) {
          next('/sign-in-ed')
        }

        if(localStorage.getItem('pt') === "bibo" && to.path.indexOf("/sign-in-bibo") < 0) {
          next('/sign-in-bibo')
        }

        if(localStorage.getItem('pt') === "iglow" && to.path.indexOf("/sign-in-iglow") < 0) {
          next('/sign-in-iglow')
        }

        if(localStorage.getItem('pt') === "ecc" && to.path.indexOf("/sign-in-ecc") < 0) {
          next('/sign-in-ecc')
        }



        if(localStorage.getItem('pt') === "ovi" && (to.path.indexOf("/sign-in-ec") >= 0 || to.path.indexOf("/sign-in-ed") >= 0)) {
          next('/sign-in')
        }
      }

  if (to.matched.some(record => record.meta.loginRequired)) {
    if (isAuthGuardActive) {
      const user = getCurrentUser();

      if (user && localStorage.getItem('ecmtctoken') != null) {
        //console.log('user role');
        //console.log(user);
        const roleArrayHierarchic = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);
        if (roleArrayHierarchic.every(x => x.includes(user.role))) {
          next();
        } else {
          next('/404')
        }
      } else {
        setCurrentUser(null);
        if(localStorage.getItem('pt') === "ecp") {
          next('/sign-in-ec')
        } else if(localStorage.getItem('pt') === "edp") {
          next('/sign-in-ed')
        } else if(localStorage.getItem('pt') === "bibo") {
          next('/sign-in-bibo')
        } else if(localStorage.getItem('pt') === "iglow") {
          next('/sign-in-iglow')
        } else if(localStorage.getItem('pt') === "ecc") {
          next('/sign-in-ecc')
        } else { 
          next('/sign-in')
        }
      }
    } else {
      next();
    }
  } else {
    next()
  }
}
