import { createApp } from "vue";
import App from "./App.vue";
 

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
//import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {BlockUI} from 'vue-blockui';

import { Skeleton } from 'vue-loading-skeleton';
import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

import VueGoogleCharts from 'vue-google-charts' ;

import 'vue-blockui/dist/vue-blockui.css'
//import BlockUI from 'vue-blockui/dist/vue-blockui.common'
import VueSocialSharing from 'vue-social-sharing'



import dayjs from 'dayjs';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

app.use(VueGoogleCharts);
app.use(BlockUI);

app.use(VueSocialSharing);

   

ApiService.init(app);
//MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");


app.mixin({
    methods: {
        numberWithCommas(x:any){
            let n = x.toString().split(".");
            n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //Combines the two sections
            if(n[1] == undefined){
                n[1] = "00";
            }
        
            return n[0] + "." + n[1].substr(0, 2);
            },
        randomStringGenerate: function(len) { 
            let text = ""
            let chars = "abcdefghijklmnopqrstuvwxyz1234567890" 
            for( let i=0; i < len; i++ ) {
                    text += chars.charAt(Math.floor(Math.random() * chars.length))
            } 
                return text
        },
        showModalMessage(status, message){
            Swal.fire({
                text: message,
                icon: status,
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              });
        },

        formatDate(dateString) {
            const date = dayjs(dateString);
                // Then specify how you want your dates to be formatted
            return date.format('dddd MMMM D, YYYY');
        },
        encodeNewSealPro(){
            
        }
    }
  });